<template>
  <div
    class="develop col-11 col-sm-10 m-auto"
    data-aos="fade-up"
    data-aos-duration="500"
    data-aos-offset="300"
  >
    <div class="develop__content">{{ data.title }}</div>
    <div class="develop__container">
      <img v-lazy="circleImg" alt="circleImg" />
      <div class="develop__container__detail">
        <p>{{ data.sub }}</p>
        <h3 class="d-none d-sm-block">{{ data.conslusion }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'develop',
  data() {
    return {
      circleImg: '/images/people/circle.png',
      data: this.$t('people').develop
    };
  },
  watch: {
    lang() {
      this.data = this.$t('people').develop;
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
img {
  object-fit: cover;
}
.develop {
  margin: auto;
  background-image: url('/images/people/develop.png');
  background-size: cover;
  padding-top: 90px;
  .develop__content {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    color: var(--blue-text);
    padding: 0 60px;
  }
  .develop__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10%;
    padding: 30px 60px 50px;
  }
  .develop__container__detail {
    font-size: 18px;
    p {
      margin-bottom: 30px;
      color: var(--grey-text);
    }
    h3 {
      position: relative;
      color: var(--blue-text);
      font-size: 21px;
      padding-top: 20px;
      line-height: 1.46;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 12%;
        height: 4px;
        background-color: var(--orange);
      }
    }
  }
  @media (max-width: 1024px) {
    .develop__container {
      flex-direction: column;
      gap: 30px;
      img {
        width: 30%;
      }
    }
  }
  @media (max-width: 768px) {
    .develop__content {
      font-size: 32px;
      padding: 0 8px;
    }
    .develop__container {
      padding: 20px;
      padding-top: 40px;
      img {
        width: 80%;
      }
    }
  }
}
</style>
